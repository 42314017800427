import "../css/styles.css";

import * as React from "react";

import { Analytics } from "@vercel/analytics/react";
import App from "next/app";
import { DefaultSeo } from "next-seo";
import { FontCabin } from "../components/fonts/cabin";
import { FontPlayfair } from "../components/fonts/playfair";
import { Provider as JotaiProvider } from "jotai";
import Router from "next/router";
import { useTimeoutFn } from "react-use";

class CustomApp extends App {
  render() {
    const { Component, pageProps } = this.props;

    return (
      <>
        <>
          <DefaultSeo
            title={"Topher Winward"}
            description={"Joy finder and software developer"}
            openGraph={{
              type: "website",
              locale: "en_gb",
              url: "https://topher.io",
              site_name: "topher.io",
            }}
            twitter={{ handle: "@winwardo" }}
          />
        </>
        <PageTracker>
          <JotaiProvider>
            <Component {...pageProps} />
          </JotaiProvider>
        </PageTracker>
        <Analytics />
      </>
    );
  }
}

export const GTMPageView = (url: string) => {
  interface PageEventProps {
    event: string;
    page: string;
  }

  const pageEvent: PageEventProps = {
    event: "pageview",
    page: url,
  };
  //@ts-ignore
  window && window.dataLayer && window.dataLayer.push(pageEvent);
  return pageEvent;
};

function PageTracker({ children }) {
  React.useEffect(() => {
    const handleRouteChange = (url: string) => GTMPageView(url);
    Router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      Router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, []);

  return <>{children}</>;
}

function AsyncPreloadFonts() {
  const [shouldLoad, setShouldLoad] = React.useState(false);
  useTimeoutFn(() => {
    setShouldLoad(true);
  }, 1000);
  if (shouldLoad) {
    return (
      <div className="absolute opacity-0">
        <FontCabin>&nbsp;</FontCabin>
        <FontPlayfair>
          <span className="font-normal">&nbsp;</span>
        </FontPlayfair>
      </div>
    );
  } else {
    return null;
  }
}

export default CustomApp;
