import Head from "next/head";

export function FontPlayfair({ children }) {
  return (
    <>
      <Head>
        <link
          rel="preload"
          href="/_next/static/media/playfair-display-v20-latin-regular.447195e486657fe7a8196deb4f9ae5b7.woff2"
          as="font"
          type="font/woff2"
          crossOrigin=""
        />
      </Head>
      <span className={"font-playfair"}>{children}</span>
    </>
  );
}
